import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import { FaTwitterSquare } from "@react-icons/all-files/fa/FaTwitterSquare"
import { FaInstagramSquare } from "@react-icons/all-files/fa/FaInstagramSquare"
import { FaDiscord } from "@react-icons/all-files/fa/FaDiscord"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
// import { FaTelegram } from "@react-icons/all-files/fa/FaTelegram"
// import { FaEnvelopeSquare } from "@react-icons/all-files/fa/FaEnvelopeSquare"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `About`
  const social = data.site.siteMetadata?.social

  const params = {
    inc: 0.12,
    zInc: 0.0004,
  }

  return (
    <Layout location={location} title={siteTitle} params={params}>
      <Seo title="About" />
      <div className="main-content">
        <Bio />
        <br></br>
        My primary techstack for web applications is: React, Gatsby, NextJS,
        Capacitor, AWS (Cloud Microservices), JavaScript/TypeScript/CSS,
        Python/C#/Yaml, and CICD with Gitlab or Azure DevOps.
        <br></br>
        <br></br>
        I'm open to opportunities as a developer or generative artist. You can
        find me on social platforms under 'BobeldijkArt,' where I recently
        started sharing my NFT projects. While I’m not a big fan of social
        media, I occasionally use it for PR.
        <br></br>
        <br></br>
        Get in touch by
        <br></br>
        <br></br>
        <a
          aria-label="Weblink Twitter"
          href={`https://twitter.com/${social?.twitter || ``}`}
        >
          <FaTwitterSquare className="icon-content" size="2em" />
        </a>
        {/* <a aria-label="Weblink e-mail" href="mailto: bobeldijkart@gmail.com">
          <FaEnvelopeSquare className="icon-content" size="2em" />
        </a> */}
        <a
          aria-label="Weblink Instagram"
          href={`https://instagram.com/${social?.twitter || ``}`}
        >
          <FaInstagramSquare className="icon-content" size="2em" />
        </a>
        <a
          aria-label="Weblink Discord"
          href={`https://discord.com/BobeldijkArt#5323`}
        >
          <FaDiscord className="icon-content" size="2em" />
        </a>
        <a
          aria-label="Weblink LinkedIn"
          href={`https://www.linkedin.com/in/mark-bobeldijk/}`}
        >
          <FaLinkedin className="icon-content" size="2em" />
        </a>
        {/* <a aria-label="Weblink Telegram" href={`https://web.telegram.org/@BobeldijkArt}`}><FaTelegram className="icon-content" size='2em' /></a> */}
      </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
      }
    }
  }
`
